import * as React from 'react'
import { hot } from 'react-hot-loader'
import queryString from 'query-string'
import { asyncComponent } from 'react-async-component'
import { Route, Switch } from 'react-router-dom'
import * as moment from 'moment'

interface Props {
	className: string
}

class App extends React.Component<Props> {
	constructor(props: any, context: any) {
		super(props, context)
	}

	render() {
		const { className } = this.props
		return (
			<div className={className}>
				<Switch>
					<Route
						path="/Api/V2"
						render={() => {
							const Page = asyncComponent({
								resolve: () => import('./ApiV2'),
							})
							return <Page />
						}}
					/>

					<Route
						path="/Api/V2ea1"
						render={() => {
							const Page = asyncComponent({
								resolve: () => import('./ApiV2ea1'),
							})
							return <Page />
						}}
					/>

					<Route
						exact
						path="/assets/:id"
						render={({ match }) => {
							const AssetsPage = asyncComponent({
								resolve: () => import('./Assets/AssetsPage'),
							})
							return <AssetsPage {...match.params} useRouter={true} />
						}}
					/>
					<Route
						exact
						path="/calls/form_saleprocess_products/:CallId/:Actionid/:NextActionId?"
						render={({ match }) => {
							const SalePage = asyncComponent({
								resolve: () => import('./SalePage'),
							})
							return (
								<SalePage
									CallId={parseInt(match.params.CallId)}
									ActionId={parseInt(match.params.Actionid)}
									NextActionId={parseInt(match.params.NextActionId)}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/calls/form_saleprocess_payment/:CallId/:Actionid/:NextActionId?"
						render={({ match }) => {
							const SalePaymentPage = asyncComponent({
								resolve: () => import('./SalePaymentPage'),
							})
							return (
								<SalePaymentPage
									CallId={parseInt(match.params.CallId)}
									ActionId={parseInt(match.params.Actionid)}
									NextActionId={parseInt(match.params.NextActionId)}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/job_lead/communication/:JobId/:OpportunityId/:Page?"
						render={props => {
							const { match } = props
							const JobLeadCommunication = asyncComponent({
								resolve: () => import('./JobLeadCommunication'),
							})
							return (
								<JobLeadCommunication
									{...props}
									Page={match.params.Page}
									JobId={parseInt(match.params.JobId)}
									OpportunityId={parseInt(match.params.OpportunityId)}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/job_lead/front/:JobId/:Page?"
						render={props => {
							const JobLeadFront = asyncComponent({
								resolve: () => import('./JobLeadFront'),
							})
							return (
								<JobLeadFront
									{...props}
									Page={props.match.params.Page}
									JobId={parseInt(props.match.params.JobId)}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/reports/orders/"
						render={props => {
							const OrderOverview = asyncComponent({
								resolve: () => import('./Orders/OrderOverview'),
							})
							return <OrderOverview {...props} />
						}}
					/>
					<Route
						exact
						path="/reports/order/:OrderId"
						render={props => {
							const DisplayOrder = asyncComponent({
								resolve: () => import('./Orders/DisplayOrder'),
							})
							return <DisplayOrder {...props} OrderId={parseInt(props.match.params.OrderId)} />
						}}
					/>
					<Route
						exact
						path="/Jobs"
						render={props => {
							const JobsList = asyncComponent({
								resolve: () => import('./Pages/Jobs/List/JobsList'),
							})
							return <JobsList sent={false} deleted={false} statusIdIn={[1]} page={1} subTitle="Active" />
						}}
					/>
					<Route
						exact
						path="/Jobs/active/:page(\d+)?"
						render={props => {
							const JobsList = asyncComponent({
								resolve: () => import('./Pages/Jobs/List/JobsList'),
							})
							let page = 1
							if (props.match.params.page) {
								page = parseInt(props.match.params.page)
							}
							return (
								<JobsList sent={false} deleted={false} statusIdIn={[1]} page={page} subTitle="Active" />
							)
						}}
					/>
					<Route
						exact
						path="/Jobs/hidden/:page(\d+)?"
						render={props => {
							const JobsList = asyncComponent({
								resolve: () => import('./Pages/Jobs/List/JobsList'),
							})
							let page = 1
							if (props.match.params.page) {
								page = parseInt(props.match.params.page)
							}
							return (
								<JobsList sent={false} deleted={false} statusIdIn={[2]} page={page} subTitle="Hidden" />
							)
						}}
					/>
					<Route
						exact
						path="/Jobs/sent/:page(\d+)?"
						render={props => {
							const JobsList = asyncComponent({
								resolve: () => import('./Pages/Jobs/List/JobsList'),
							})
							let page = 1
							if (props.match.params.page) {
								page = parseInt(props.match.params.page)
							}
							return (
								<JobsList sent={true} deleted={false} statusIdIn={[0, 1]} page={page} subTitle="Sent" />
							)
						}}
					/>
					<Route
						exact
						path="/Jobs/deleted/:page(\d+)?"
						render={props => {
							const JobsList = asyncComponent({
								resolve: () => import('./Pages/Jobs/List/JobsList'),
							})
							let page = 1
							if (props.match.params.page) {
								page = parseInt(props.match.params.page)
							}
							return <JobsList statusIdIn={[1]} deleted={true} page={page} subTitle="Deleted" />
						}}
					/>
					<Route
						exact
						path="/Jobs/WebApps/List"
						render={props => {
							const WebAppOverview = asyncComponent({
								resolve: () => import('./Pages/WebApps/Overview'),
							})
							return <WebAppOverview />
						}}
					/>
					<Route
						exact
						path="/Jobs/NewEmail"
						render={props => {
							const NewEmail = asyncComponent({
								resolve: () => import('./Pages/Jobs/Emails/NewEmail'),
							})
							return <NewEmail />
						}}
					/>

					<Route
						exact
						path="/reports/SaleCampaignLatestSalesDashboard/:jobId?"
						render={props => {
							const { match } = props
							const SaleCampaignLatestSalesDashboard = asyncComponent({
								resolve: () => import('./Pages/Reports/SaleCampaignLatestSalesDashboard'),
							})
							return <SaleCampaignLatestSalesDashboard jobId={parseInt(match.params.jobId)} />
						}}
					/>

					<Route
						exact
						path="/reports/OpportunityLatestSalesDashboard/:jobId?"
						render={props => {
							const OpportunityLatestSalesDashboard = asyncComponent({
								resolve: () => import('./Pages/Reports/OpportunityLatestSalesDashboard'),
							})
							return <OpportunityLatestSalesDashboard jobId={parseInt(props.match.params.jobId)} />
						}}
					/>

					<Route
						exact
						path="/reports/OpportunityTopSellersMonthlyDashboard"
						render={props => {
							const OpportunityTopSellersMonthlyDashboard = asyncComponent({
								resolve: () => import('./Pages/Reports/OpportunityTopSellersMonthlyDashboard'),
							})
							return <OpportunityTopSellersMonthlyDashboard />
						}}
					/>

					<Route
						exact
						path="/orders/Overview"
						render={props => {
							const OrderOverview = asyncComponent({
								resolve: () => import('./Orders/OrderOverview'),
							})
							return <OrderOverview {...props} />
						}}
					/>
					<Route
						exact
						path="/orders/:OrderId"
						render={props => {
							const DisplayOrder = asyncComponent({
								resolve: () => import('./Orders/DisplayOrder'),
							})
							return <DisplayOrder {...props} OrderId={parseInt(props.match.params.OrderId)} />
						}}
					/>

					<Route
						exact
						path="/job_lead/HandleSuccess/:OpportunityId/:Actionid/:NextActionId?"
						render={props => {
							const { match } = props

							const SuccessOpportunity = asyncComponent({
								resolve: () => import('./Pages/Jobs/Leads/Success'),
							})
							return (
								<SuccessOpportunity
									OpportunityId={parseInt(match.params.OpportunityId)}
									ActionId={parseInt(match.params.Actionid)}
									NextActionId={parseInt(match.params.NextActionId)}
								/>
							)
						}}
					/>

					<Route
						exact
						path="/job_lead/Booking/:JobId"
						render={props => {
							const { match } = props
							const Booking = asyncComponent({
								resolve: () => import('./Pages/Jobs/Leads/Communication/Booking'),
							})

							return <Booking JobId={parseInt(match.params.JobId)} />
						}}
					/>

					<Route
						exact
						path="/reports/:jobs_list?"
						render={props => {
							const { match } = props
							const Page = asyncComponent({
								resolve: () => import('./Pages/Reports/ReportsList'),
							})
							return <Page />
						}}
					/>

					<Route
						exact
						path="/jobs/input_content/:jobId"
						render={props => {
							const { match } = props
							const InputContent = asyncComponent({
								resolve: () => import('./Pages/Jobs/Emails/InputContent'),
							})
							return <InputContent JobId={parseInt(match.params.jobId)} />
						}}
					/>

					<Route
						exact
						path="/lists/:id"
						render={props => {
							const { match } = props
							const query = queryString.parse(props.location.search)
							const page = query.page ? parseInt(query.page, 10) : undefined
							const search = query.search ? query.search : undefined
							const dateFrom = query.from ? query.from : undefined
							const dateTo = query.to ? query.to : undefined
							const Lists = asyncComponent({
								resolve: () => import('./Pages/Lists/Index'),
							})

							return <Lists page={page} search={search} from={dateFrom} to={dateTo} {...match.params} />
						}}
					/>
					<Route
						exact
						path="/Lists/Companies/:id"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Lists/Companies'),
							})

							const { match } = props
							const query = queryString.parse(props.location.search)
							const page = query.page ? parseInt(query.page, 10) : undefined
							const search = query.search ? query.search : undefined
							const connectedCompanies = query.connectedCompanies
								? query.connectedCompanies === 'true'
								: false
							return (
								<Page
									page={page}
									search={search}
									connectedCompanies={connectedCompanies}
									{...match.params}
								/>
							)
						}}
					/>

					<Route
						exact
						path="/Lists/Contacts/:id"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Lists/Contacts'),
							})

							const { match } = props
							const query = queryString.parse(props.location.search)
							const page = query.page ? parseInt(query.page, 10) : undefined
							const search = query.search ? query.search : undefined
							const connectedContacts = query.connectedContacts
								? query.connectedContacts === 'true'
								: false
							return (
								<Page
									page={page}
									search={search}
									connectedContacts={connectedContacts}
									{...match.params}
								/>
							)
						}}
					/>

					<Route
						exact
						path="/audiences/companies/:id"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Audiences/Companies'),
							})
							const { match } = props
							const query = queryString.parse(props.location.search)
							const page = query.page ? parseInt(query.page, 10) : undefined
							const search = query.search ? query.search : undefined
							const connectedCompanies = query.connectedCompanies
								? query.connectedCompanies === 'true'
								: false
							return (
								<Page
									page={page}
									search={search}
									connectedCompanies={connectedCompanies}
									{...match.params}
								/>
							)
						}}
					/>

					<Route
						exact
						path="/audiences/contacts/:id"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Audiences/Contacts'),
							})
							const { match } = props
							const query = queryString.parse(props.location.search)
							const page = query.page ? parseInt(query.page, 10) : undefined
							const search = query.search ? query.search : undefined
							const connectedContacts = query.connectedContacts
								? query.connectedContacts === 'true'
								: false
							return (
								<Page
									page={page}
									search={search}
									connectedContacts={connectedContacts}
									{...match.params}
								/>
							)
						}}
					/>

					<Route
						exact
						path="/audiences/:cateId/:id"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Audiences/Index'),
							})
							const { match } = props
							const query = queryString.parse(props.location.search)
							const page = query.page ? parseInt(query.page, 10) : undefined
							const search = query.search ? query.search : undefined
							const dateFrom = query.from ? query.from : undefined
							const dateTo = query.to ? query.to : undefined
							return <Page page={page} search={search} from={dateFrom} to={dateTo} {...match.params} />
						}}
					/>

					<Route
						exact
						path="/settings/add_reviewer"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Settings/Reviewers/AddReviewer'),
							})
							return <Page />
						}}
					/>

					<Route
						exact
						path="/settings/edit_reviewer/:id?"
						render={props => {
							const EditReviewer = asyncComponent({
								resolve: () => import('./Pages/Settings/Reviewers/EditReviewer'),
							})
							return <EditReviewer previewId={parseInt(props.match.params.id)} />
						}}
					/>

					<Route
						exact
						path="/settings/senders_add"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Settings/Senders/AddSender'),
							})
							return <Page />
						}}
					/>

					<Route
						exact
						path="/settings/senders_modify/:id?"
						render={props => {
							const EditSender = asyncComponent({
								resolve: () => import('./Pages/Settings/Senders/EditSender'),
							})
							return <EditSender senderId={parseInt(props.match.params.id)} />
						}}
					/>

					<Route
						exact
						path="/settings/Reviewers"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Settings/Reviewers/ReviewersList'),
							})
							return <Page />
						}}
					/>

					<Route
						exact
						path="/settings/senders"
						render={props => {
							const SendersList = asyncComponent({
								resolve: () => import('./Pages/Settings/Senders/SendersList'),
							})
							return <SendersList />
						}}
					/>

					<Route
						exact
						path="/settings/user_add"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Settings/Users/AddUser'),
							})
							return <Page />
						}}
					/>
					<Route
						exact
						path="/settings/Users"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Settings/Users/UsersList'),
							})
							return <Page />
						}}
					/>
					<Route
						exact
						path="/settings/usergroups_list"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/Settings/Users/UserGroupsList'),
							})
							return <Page />
						}}
					/>
					<Route
						exact
						path="/user/invite_create_global_user/:token"
						render={props => {
							const InviteGlobalUserForm = asyncComponent({
								resolve: () => import('./Pages/public/InviteGlobalUserForm'),
							})
							return <InviteGlobalUserForm token={props.match.params.token} />
						}}
					/>
					<Route
						exact
						path="/user/user_wizard/"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/public/CreateGlobalUserForm'),
							})
							return <Page />
						}}
					/>
					<Route
						exact
						path="/user/password_reset/:token"
						render={props => {
							const Page = asyncComponent({
								resolve: () => import('./Pages/public/PasswordReset'),
							})
							return <Page token={props.match.params.token} />
						}}
					/>
				</Switch>
			</div>
		)
	}
}

export default hot(module)(App)
